































import { Component, Vue } from 'vue-property-decorator';
import { NotFoundMessage } from '@/constants/util';
import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import SingleText from '@/components/common/elements/SingleText.vue';
import authState from '@/store/auth';

@Component({
  components: {
    Header,
    SideMenu,
    SingleText
  }
})
export default class NotFound extends Vue {
  /** メッセージ本文 */
  message = NotFoundMessage;

  /** ログイン状態。ログインしている場合はtrue */
  get isLogined() {
    return authState.isLogin;
  }
}
